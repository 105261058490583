import { Route } from 'vue-router/types/router'
import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'
import { SermonRequestOptions } from '~/apiclient/apisermons'
import { getStrValuesFromEnum } from '~/assets/ts/enums'
import {
  SpeakerRequestOptions,
  SpeakerSortOption,
} from '~/apiclient/apispeakers'
import { SeriesRequestOptions } from '~/apiclient/apiseries'
import {
  DurationFilterToSermonOptions,
  MediaFilterToSermonOptions,
} from '~/apiclient/apifilters'

/**
 * Takes a string and returns whether that value is falsy
 * @example qsToBool(this.$route.query.debug)
 * */
export function qsToBool(value: string | (string | null)[]): boolean {
  if (!value) return false
  return ['true', '1'].includes(value as string)
}

/** Takes a bool and turns it into the QS version of that bool
 * @example qsToBool(this.$route.query.debug)
 * */
export function boolToQs(value: boolean): string | undefined {
  return value ? '1' : '0'
}

/** Retrieves a String from the $nuxt context query string
 * @example qsValue(this, 'q')
 * */
export function qsValue(context: Record<any, any>, qs: string) {
  const route = (context.$route ?? context.route) as Route | undefined
  if (!route) return undefined
  return route.query[qs] as string | undefined
}

/** Retrieves an Int from the $nuxt context query string
 * @example qsInt(this, 'page')
 * */
export function qsInt(context: Record<any, any>, qs: string) {
  const value = qsValue(context, qs)
  if (!value) return undefined
  return parseInt(value)
}

/** Retrieves a Bool from the $nuxt context query string
 * @example qsBool(this, 'debug')
 * */
export function qsBool(
  context: Record<any, any>,
  qs: string,
  defaultValue = false
) {
  const value = qsValue(context, qs)
  return value ? qsToBool(value) : defaultValue
}

export interface QsPair {
  key: string
  value: string | (string | null)[] | boolean | number | undefined
}

export function qsPairAsObj(pairs: QsPair[]) {
  const obj = {} as Record<string, any>
  pairs
    .filter((p) => p.value)
    .forEach((p) => {
      const isBool = typeof p.value === 'boolean'
      obj[p.key] = isBool ? boolToQs(p.value as boolean) : p.value
    })
  return obj
}

export function qsPairToUrl(baseUrl: string, pairs: QsPair[]) {
  return assembleUrl(baseUrl, qsPairAsObj(pairs))
}

export function assembleUrl(baseUrl: string, params: Record<string, any>) {
  Object.keys(params).forEach((key) => {
    if (params[key] === undefined) {
      delete params[key]
    }
  })
  const qs = new URLSearchParams(params).toString().replaceAll('+', ' ')
  return qs ? `${baseUrl}?${qs}` : baseUrl
}

/** Update current route query string with the specified value(s) */
export async function updateQs(context: Vue, pairs: QsPair[], reload = false) {
  const pairsAsObj = qsPairAsObj(pairs)
  if (reload) {
    const config = { ...context.$route.query, ...pairsAsObj }
    await context.$navigateTo(config)
  } else {
    modifyQs(context, pairsAsObj)
    // const url = qsPairToUrl(context.$route.path, pairs)
    // history.pushState({}, '', url)
  }
}

export function getQsRecord() {
  const params = new URLSearchParams(window.location.search)
  const queryParams = {} as Record<string, string>

  for (const [key, value] of params.entries()) {
    if (value) {
      queryParams[key] = value
    }
  }

  return queryParams
}

/** Modifies the current url query string without reloading the page. This is non-reactive and is simply used for page reloads and things like it. */
export function modifyQs(context: Vue, qs: Record<string, string | undefined>) {
  const existingQuery = getQsRecord()
  let newQuery = { ...existingQuery, ...qs } as Record<string, any>

  // Remove falsy values
  newQuery = Object.fromEntries(
    Object.entries(newQuery).filter(([_, value]) => Boolean(value))
  )

  let query = new URLSearchParams(newQuery).toString()
  if (query) {
    query = `?${query}`
  }
  history.pushState({}, '', `${context.$route.path}${query}`)
  return query
}

/**
 * This returns the page parameters when passing in either .vue pages/components context or middleware context.
 * These params are "safe" because they are stored even in a domain rewrite scenario
 */
export function customSiteParams(
  context: Record<any, any>
): Record<string, string> {
  const route = context.$route ? context.$route : context.route
  const store = context.$store ? context.$store : context.store
  return {
    ...store.getters.rewriteParams,
    ...route.params,
  }
}

export enum SermonFilterCategories {
  Broadcaster = 'broadcasterID',
  Event = 'eventType',
  Duration = 'duration',
  Denomination = 'denomination',
  Language = 'languageCode',
  Search = 'searchKeyword',
  Series = 'series',
  Speaker = 'speakerID',
  Book = 'book',
  Chapter = 'chapter',
  Year = 'year',
  Media = 'media',
}

export function GetChildCategory(category: SermonFilterCategories) {
  if (category === SermonFilterCategories.Book)
    return SermonFilterCategories.Chapter
  return undefined
}

export function ShouldSearchCategory(
  category: SermonFilterCategories
): boolean {
  switch (category) {
    case SermonFilterCategories.Broadcaster:
    case SermonFilterCategories.Series:
    case SermonFilterCategories.Speaker:
      return true
    default:
      return false
  }
}

export function SermonFilterCategoryTitle(
  vue: Vue,
  category: SermonFilterCategories
) {
  switch (category as SermonFilterCategories) {
    case SermonFilterCategories.Broadcaster:
      return vue.$t('Broadcaster')
    case SermonFilterCategories.Event:
      return vue.$t('Category')
    case SermonFilterCategories.Language:
      return vue.$t('Language')
    case SermonFilterCategories.Denomination:
      return vue.$t('Denomination')
    case SermonFilterCategories.Series:
      return vue.$t('Series')
    case SermonFilterCategories.Speaker:
      return vue.$t('Speaker')
    case SermonFilterCategories.Year:
      return vue.$t('Year')
    case SermonFilterCategories.Book:
      return vue.$t('Scripture')
    case SermonFilterCategories.Chapter:
      return vue.$t('Chapter')
    case SermonFilterCategories.Search:
      return vue.$t('Search')
    case SermonFilterCategories.Duration:
      return vue.$t('Duration')
    case SermonFilterCategories.Media:
      return vue.$t('Media')
    default:
      return category
  }
}

export interface SermonFilterSelection {
  category: SermonFilterCategories
  value: string
  display?: TranslateResult
  subtitle?: TranslateResult
  imageURL?: string
  editing?: boolean
}

/**
 * This returns SermonRequestOptions using the passed in SermonFilterSelections
 */
export function FiltersToSermonRequestOptions(
  filters: SermonFilterSelection[] | undefined
): SermonRequestOptions {
  if (!filters) return {} as SermonRequestOptions
  let options = {} as Record<string, any>
  filters.forEach((f) => {
    if (f.category === SermonFilterCategories.Duration) {
      options = { ...options, ...DurationFilterToSermonOptions(f.value) }
    } else if (f.category === SermonFilterCategories.Media) {
      options = { ...options, ...MediaFilterToSermonOptions(f.value) }
    } else {
      options[f.category] = f.value
    }
  })
  return options as SermonRequestOptions
}

/**
 * This returns SermonFilterSelections from the page's query strings
 */
export function GetSermonFiltersFromQs(
  context: Record<any, any>
): SermonFilterSelection[] {
  const categories = getStrValuesFromEnum(SermonFilterCategories)
  const queries = context.$route.query as Record<string, string | number>
  const filters = [] as SermonFilterSelection[]
  Object.keys(queries).forEach((key) => {
    if (categories.includes(key)) {
      const category = key as SermonFilterCategories
      filters.push({
        category,
        value: queries[key].toString(),
      })
    }
  })
  return filters
}

/**
 * This returns QsPair[] format of the passed in SermonFilterSelections
 */
export function SermonFiltersQsPairs(
  filters: SermonFilterSelection[]
): QsPair[] {
  const pairs = [] as QsPair[]
  filters.forEach((f) => {
    pairs.push({ key: f.category, value: f.value })
  })
  return pairs
}

export enum SpeakerFilterCategories {
  Random = 'randomize',
}

export interface SpeakerFilterSelection {
  category: SpeakerFilterCategories
  value: string
  display?: TranslateResult
  subtitle?: TranslateResult
  imageURL?: string
}

/**
 * This returns SpeakerRequestOptions using the passed in SpeakerFilterSelections
 */
export function FiltersToSpeakerRequestOptions(
  filters: SpeakerFilterSelection[] | undefined
): SpeakerRequestOptions {
  if (!filters) return {} as SpeakerRequestOptions
  const options = {} as Record<string, any>
  filters.forEach((f) => {
    options[f.category] = f.value
  })
  return options as SpeakerRequestOptions
}

/**
 * This returns SpeakerFilterSelections from the page's query strings
 */
export function GetSpeakerFiltersFromQs(
  context: Record<any, any>
): SpeakerFilterSelection[] {
  const categories = getStrValuesFromEnum(SpeakerFilterCategories)
  const queries = context.$route.query as Record<string, string | number>
  const filters = [] as SpeakerFilterSelection[]
  Object.keys(queries).forEach((key) => {
    if (categories.includes(key)) {
      const category = key as SpeakerFilterCategories
      filters.push({
        category,
        value: queries[key].toString(),
      })
    }
  })
  return filters
}

/**
 * This returns QsPair[] format of the passed in SpeakerFilterSelections
 */
export function SpeakerFiltersQsPairs(
  filters: SpeakerFilterSelection[]
): QsPair[] {
  const pairs = [] as QsPair[]
  filters.forEach((f) => {
    pairs.push({ key: f.category, value: f.value })
  })
  return pairs
}

/**
 * This returns SpeakerSortOptions using the current url's Qs
 */
export function GetSpeakerSortFromQs(
  context: Record<any, any>,
  defaultSort: SpeakerSortOption = SpeakerSortOption.Newest
): SpeakerSortOption {
  const qsSort = qsValue(context, 'sort') as SpeakerSortOption
  const validQs = getStrValuesFromEnum(SpeakerSortOption).includes(qsSort)
  return validQs ? qsSort : defaultSort
}

export enum SeriesFilterCategories {
  FilterBy = 'filterBy',
}

export interface SeriesFilterSelection {
  category: SeriesFilterCategories
  value: string
  display?: TranslateResult
  subtitle?: TranslateResult
  imageURL?: string
}

/**
 * This returns SermonRequestOptions using the passed in SermonFilterSelections
 */
export function FiltersToSeriesRequestOptions(
  filters: SeriesFilterSelection[] | undefined
): SeriesRequestOptions {
  if (!filters) return {} as SeriesRequestOptions
  const options = {} as Record<string, any>
  filters.forEach((f) => {
    options[f.category] = f.value
  })
  return options as SeriesRequestOptions
}

/**
 * This returns SermonFilterSelections from the page's query strings
 */
export function GetSeriesFiltersFromQs(
  context: Record<any, any>
): SeriesFilterSelection[] {
  const categories = getStrValuesFromEnum(SeriesFilterCategories)
  const queries = context.$route.query as Record<string, string | number>
  const filters = [] as SeriesFilterSelection[]
  Object.keys(queries).forEach((key) => {
    if (categories.includes(key)) {
      const category = key as SeriesFilterCategories
      filters.push({
        category,
        value: queries[key].toString(),
      })
    }
  })
  return filters
}

/**
 * This returns QsPair[] format of the passed in SermonFilterSelections
 */
export function SeriesFiltersQsPairs(
  filters: SeriesFilterSelection[]
): QsPair[] {
  const pairs = [] as QsPair[]
  filters.forEach((f) => {
    pairs.push({ key: f.category, value: f.value })
  })
  return pairs
}
