import { ApiClient } from '~/apiclient/apiclient'
import {
  SermonApi,
  SermonApiEventType,
  SermonSocialSharingSettings,
} from '~/apiclient/apisermons'
import { DefaultSermonTitle, Sermon } from '~/models/sermon'
import { Broadcaster } from '~/models/broadcaster'
import { SpeakerApi, UnknownSpeakerID } from '~/apiclient/apispeakers'
import { jsDateToApiDateStr } from '~/assets/ts/utils/date'
import {
  BroadcasterSocialSharingConfig,
  SermonCountsApi,
} from '~/apiclient/apibroadcasters'
import { EventTypeApi, LanguageApi } from '~/apiclient/apifilters'
import { SeriesApi } from '~/apiclient/apiseries'
import { LanguageCode3 } from '~/models/media'

export interface SermonEditParams {
  publishNow?: boolean
  publishTimestamp?: number | null
  eventType?: SermonApiEventType
  languageCode?: string
  subtitle?: string | null
  speakerID?: number
  seriesID?: number | null
  preachDate?: string
  acceptCopyright?: boolean
  fullTitle?: string
  socialSharingSettings?: SermonSocialSharingSettings
}

export interface SermonCreateParams extends SermonEditParams {
  broadcasterID: string
  speakerID: number
}

export async function createSermon(
  this: ApiClient,
  broadcaster: Broadcaster,
  locale: string
): Promise<SermonApi> {
  let languageCode = broadcaster.english ? locale : broadcaster.languageCode
  if (languageCode === 'gb') languageCode = 'en-gb'
  const { data } = await this.$axios.post('node/sermons', {
    broadcasterID: broadcaster.id,
    fullTitle: DefaultSermonTitle,
    speakerID: UnknownSpeakerID,
    eventType: 'Sunday Service',
    preachDate: jsDateToApiDateStr(new Date()),
    languageCode,
    acceptCopyright: true,
  } as SermonCreateParams)
  return data
}

export async function deleteSermon(
  this: ApiClient,
  sermonID: string
): Promise<string> {
  return await this.$axios.delete(`node/sermons/${sermonID}`)
}

export async function duplicateSermon(
  this: ApiClient,
  sermonID: string
): Promise<SermonApi> {
  const { data } = await this.$axios.post(`node/sermons/${sermonID}/duplicate`)
  return data
}

export async function editSermon(
  this: ApiClient,
  sermonID: string,
  params: SermonEditParams
): Promise<any> {
  return await this.$axios.patch(`node/sermons/${sermonID}`, params)
}

export async function setSermonLanguage(
  this: ApiClient,
  sermon: Sermon
): Promise<any> {
  return await this.editSermon(sermon.id, {
    languageCode: sermon.language,
  })
}

export async function putSermon(
  this: ApiClient,
  sermon: SermonApi,
  params: SermonCreateParams,
  publishTimestamp: number | null | undefined
): Promise<SermonApi> {
  const modifiedParams = {
    ...sermon,
    ...params,
  } as Record<any, any>
  if (publishTimestamp !== undefined) {
    modifiedParams.publishTimestamp = publishTimestamp
    modifiedParams.socialSharingSettings.publish = !!publishTimestamp
  }
  delete modifiedParams.socialSharing
  delete modifiedParams.publishDate
  const { data } = await this.$axios.put(
    `node/sermons/${sermon.sermonID}`,
    modifiedParams
  )
  return data
}

export interface SermonEditorDropdownContents {
  sermon: SermonApi
  speakers: SpeakerApi[]
  sermonCountsForSpeakers: SermonCountsApi[]
  sermonEventTypes: EventTypeApi[]
  languages: LanguageApi[]
  series: SeriesApi[]
  autoShortTitle: boolean
  socialSharingSettings: SermonSocialSharingSettings
  socialSharingConfigs: BroadcasterSocialSharingConfig
}

export async function sermonEditorDropdownContents(
  this: ApiClient,
  sermonID: string
): Promise<SermonEditorDropdownContents> {
  const { data } = await this.$axios.get(
    `node/sermons/${sermonID}/editor_dropdown_contents`,
    {
      params: {
        cacheTTL: 0,
      },
    }
  )
  return data
}

export interface SermonConfig {
  fullSermonTitleMin: number
  fullSermonTitleMax: number
  shortSermonTitleMax: number
  seriesTitleMin: number
  seriesTitleMax: number
  speakerNameMin: number
  speakerNameMax: number
  sermonKeywordsCharacterMax: number
  sermonDescriptionMax: number
  bibleReferenceCharacterMax: number
  bibleReferenceNumberMax: number
  preachDateFutureDays: number
  publishDateFutureDays: number
  youtubeTitleMax: number
  youtubeDescriptionMax: number
  twitterMessageMax: number
  facebookMessageMax: number
  /** Languages that can be selected to be globalized into */
  globalSermonsLanguages: string[]
  /** Languages that can be auto transcribed */
  autoCaptionLanguages: string[]
}

export async function sermonConfig(this: ApiClient): Promise<SermonConfig> {
  const { data } = await this.$axios.get(`sermon_config`)
  return data
}

export enum CaptionTypes {
  SRT = 'srt',
  VTT = 'vtt',
}

export enum UploadMediaType {
  Original = 'original',
  OriginalVideo = 'original-video',
  OriginalAudio = 'original-audio',
  OriginalThumbnail = 'original-thumbnail',
  VideoHigh = 'video-high',
  VideoLow = 'video-low',
  Video1080p = 'video-1080p',
  AudioHigh = 'audio-high',
  AudioLow = 'audio-low',
  AudioArchive = 'audio-archive',
  VideoThumbnail = 'video-thumbnail',
  Report = 'report',
  Text = 'text',
  TranscriptSource = 'transcript-source',
  VideoShort = 'video-short',
  Image = 'image',
  Caption = 'caption',
  CaptionOrig = 'caption-orig',
  ImageArticleBanner = 'image-article-banner',
  ImageArticleBannerOrig = 'image-article-banner-orig',
  ImageSpeaker = 'image-speaker',
  ImageSpeakerOrig = 'image-speaker-orig',
  ImageSpeakerThumbnail = 'image-speaker-thumbnail',
  ImageSpeakerAlbumArt = 'image-speaker-albumart',
  ImageBroadcaster = 'image-broadcaster',
  ImageBroadcasterOrig = 'image-broadcaster-orig',
  ImageBroadcasterThumbnail = 'image-broadcaster-thumbnail',
  ImageBroadcasterAlbumArt = 'image-broadcaster-albumart',
  ImageBroadcasterBanner = 'image-broadcaster-banner',
  ImageBroadcasterBannerOrig = 'image-broadcaster-banner-orig',
  ImageSponsorAd = 'image-sponsor-ad',
  ImageSponsorAdOrig = 'image-sponsor-ad-orig',
  ImagePhoto = 'image-photo',
  ImagePhotoOrig = 'image-photo-orig',
  ImagePhotoThumbnail = 'image-photo-thumbnail',
  ImageSeries = 'image-series',
  ImageSeriesOrig = 'image-series-orig',
  ImageSolo = 'image-solo',
  ImageSoloOrig = 'image-solo-orig',
  ImageUser = 'image-user',
  ImageUserOrig = 'image-user-orig',
}

export async function getCaptionUploadUrl(
  this: ApiClient,
  sermonID: string,
  captionType: CaptionTypes,
  language: LanguageCode3
): Promise<string> {
  const filename = `${sermonID}-${language}`
  const { data } = await this.$axios.post('media', {
    sermonID,
    language,
    uploadType: UploadMediaType.CaptionOrig,
    destFilename: `${filename}.orig-caption`,
    originalFilename: `${filename}.${captionType.toString().toLowerCase()}`,
  })
  return data.uploadURL
}

export interface MediaUpload {
  type: 'media_upload'
  guid: string
  sermonID: string
  uploadType: string
  destFilename: string
  priority: number
  createDate: string // ISO 8601 date format
  uploadURL: string
  uploadTusBaseURL: string
  twoStep: boolean
  originalFilename: string
  analyzeMetadata: boolean
  language: string
  targetLocation: string
  s3Endpoint: string
  autoGenerated: boolean
  apiBranch: string
}

export async function getMediaUpload(
  this: ApiClient,
  sermonID: string,
  uploadType: UploadMediaType,
  twoStep = false
): Promise<MediaUpload> {
  const { data } = await this.$axios.post('media', {
    sermonID,
    uploadType,
    twoStep,
  })
  return data
}

export async function getTextUpload(
  this: ApiClient,
  sermonID: string
): Promise<MediaUpload> {
  return await this.getMediaUpload(sermonID, UploadMediaType.Text)
}

export async function getThumbnailUpload(
  this: ApiClient,
  sermonID: string
): Promise<MediaUpload> {
  return await this.getMediaUpload(sermonID, UploadMediaType.OriginalThumbnail)
}

export interface ContinueUploadOptions {
  guid?: string
  sermonID: string
  mediaType: 'audio' | 'video'
}

export async function continueUpload(
  this: ApiClient,
  options: ContinueUploadOptions
): Promise<string> {
  if (options.guid) {
    return await this.$axios.put(`media/${options.guid}/continue`)
  }
  const uploadType =
    options.mediaType === 'audio'
      ? UploadMediaType.OriginalAudio
      : UploadMediaType.OriginalVideo
  return await this.$axios.put(`media/continue`, {
    sermonID: options.sermonID,
    uploadType,
  })
}

export interface SermonEncodingOptions {
  videoTrimStart?: number
  videoTrimEnd?: number
  audioTrimStart?: number
  audioTrimEnd?: number
  videoThumbCustom?: boolean
  videoThumbPosition?: number
  fadeAudioIn?: number
  fadeAudioOut?: number
  fadeVideoIn?: number
  fadeVideoOut?: number
  normalizeAudio?: boolean
  normalizeVideo?: boolean
  videoShortTrimStart?: number
  videoShortTrimEnd?: number
  videoShortFadeIn?: number
  videoShortFadeOut?: number
  videoForce?: boolean
}

export async function updateEncodingOptions(
  this: ApiClient,
  sermonID: string,
  encodingOptions: SermonEncodingOptions,
  twoStep = true
): Promise<void> {
  await this.$axios.patch(
    `node/sermons/${sermonID}/encoding_options`,
    encodingOptions
  )
  if (encodingOptions.videoThumbPosition && !twoStep) {
    await this.$axios.post(`jobs/encode`, {
      sermonID,
      job: 'video-thumbnail',
    })
  }
}

export async function deleteMedia(
  this: ApiClient,
  sermonID: string,
  target: 'audio' | 'video' | 'text' | 'caption' | 'all'
): Promise<void> {
  return await this.$axios.post('jobs/delete', {
    target,
    sermonID,
  })
}

export async function deleteAudio(
  this: ApiClient,
  sermonID: string
): Promise<void> {
  return await this.deleteMedia(sermonID, 'audio')
}

export async function deleteVideo(
  this: ApiClient,
  sermonID: string
): Promise<void> {
  return await this.deleteMedia(sermonID, 'video')
}

export async function deleteText(
  this: ApiClient,
  sermonID: string
): Promise<void> {
  return await this.deleteMedia(sermonID, 'text')
}

export async function deleteCaption(
  this: ApiClient,
  sermonID: string,
  language: LanguageCode3
): Promise<void> {
  return await this.$axios.post('jobs/delete', {
    target: 'caption',
    sermonID,
    language,
  })
}

export enum TrackerState {
  /** The archive quality of audio was encoded. */
  ArchiveEncoded = 'archive-encoded',
  /** The archive quality of audio was scheduled. */
  ArchiveScheduled = 'archive-scheduled',
  /** The archive quality of audio was synced. */
  ArchiveSynced = 'archive-synced',
  /** The ttranscript was scheduled */
  TranscriptScheduled = 'transcript-scheduled',
  /** The transcript was completed */
  TranscriptCompleted = 'transcript-completed',
  /** The transcript failed */
  TranscriptFailed = 'transcript-failed',
  /** The high quality of a type was encoded. */
  HighEncoded = 'high-encoded',
  /** The high quality of a type was scheduled. */
  HighScheduled = 'high-scheduled',
  /** The high quality of a type was synced. */
  HighSynced = 'high-synced',
  /** The low quality of a type was encoded. */
  LowEncoded = 'low-encoded',
  /** The low quality of a type was scheduled. */
  LowScheduled = 'low-scheduled',
  /** The low quality of a type was synced. */
  LowSynced = 'low-synced',
  /** All qualities have been scheduled. */
  OriginalAnalyzed = 'original-analyzed',
  /** Analyze failed; this is currently being used to indicate encoding failed. */
  OriginalAnalyzeFailed = 'original-analyze-failed',
  /** Pipeline failure other than encoding failure (unspecified; e.g., could be sync failure). */
  PipelineFailed = 'pipeline-failed',
  /** Original media has been uploaded to the originals bucket in B2 (or any future store for originals). */
  OriginalSynced = 'original-synced',
  /** A text file was synced. */
  TextSynced = 'text-synced',
  /** The thumbnail (for video) was synced. */
  Thumbnail = 'thumbnail',
  /** Encoding options have been set and the upload is good to encode. */
  TwoStepUploadContinued = 'two-step-upload-continued',
  /** Original media has been uploaded using the two-step process. It is waiting for "two-step-upload-continued". */
  TwoStepUploadReceived = 'two-step-upload-received',
  /** The upload was received. */
  UploadReceived = 'upload-received',
  /** The waveform (for audio) was created. */
  Waveform = 'waveform',
  /** A request to auto archive a webcast has been scheduled. */
  AutoArchiveScheduled = 'autoarchive-scheduled',
  /** The 1080p quality of video was encoded. */
  Video1080Encoded = '1080p-encoded',
  /** The 1080p quality of video was scheduled. */
  Video1080Scheduled = '1080p-scheduled',
  /** The 1080p quality of video was synced. */
  Video1080Synced = '1080p-synced',
  VideoToAudioScheduled = 'videotoaudio-scheduled',
}

export interface SermonTrackerStatus {
  status: TrackerState
  /** API Timestamp */
  updateDate: number
}

export interface SermonTrackerData {
  video: SermonTrackerStatus[]
  audio: SermonTrackerStatus[]
  text: SermonTrackerStatus[]
  transcript?: SermonTrackerStatus[]
}

export async function sermonTracker(
  this: ApiClient,
  sermonID: string
): Promise<SermonTrackerData> {
  const { data } = await this.$axios.get(
    `node/sermons/${sermonID}/media_tracker`
  )
  if (!data || Object.keys(data).length === 0) {
    throw new Error('Malformed tracker data')
  }
  return data
}

export function sermonDatabaseUrl(
  this: ApiClient,
  broadcasterID: string
): string {
  return `/node/broadcasters/${broadcasterID}/legacy-sermon-list`
}
