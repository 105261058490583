import { Context } from '@nuxt/types'
import { CanEditPage } from '~/assets/ts/utils/dashboard'
import { SermonEditorDropdownContents } from '~/apiclient/apisermoneditor'
import { PromiseAll } from '~/assets/ts/utils/misc'

export default async function (context: Context) {
  const store = context.store
  const sermonID = context.params.sermonID

  await PromiseAll([
    await store.dispatch('filters/getBible', {
      sermonID,
    }),
    await store.dispatch('dashboard/fetchSermonConfig', {
      sermonID,
    }),
    await store.dispatch('dashboard/fetchSermonEditorDropdownContents', {
      sermonID,
    }),
  ])

  const contents = store.getters['dashboard/sermonEditorDropdownContents'] as
    | SermonEditorDropdownContents
    | undefined

  if (contents) {
    CanEditPage(context, contents.sermon.broadcaster.broadcasterID)
  } else {
    context.error({
      statusCode: 404,
      message: 'Sermon not found',
    })
  }
}
