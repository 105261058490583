import {
  ApiClient,
  ApiPaginationParams,
  ApiPaginationResult,
} from '~/apiclient/apiclient'

export interface SpeakerApi {
  type: string
  speakerID: number
  displayName: string
  sortName: string
  portraitURL: string
  albumArtURL: string
  roundedThumbnailImageURL: string
  portraitURLResizable: string
  roundedThumbnailImageURLResizable: string
  bio?: string
  sermonCount: number
  mostRecentPreachDate?: string
  highlighted: {
    featured: boolean
    choice: boolean
    puritan: boolean
    reformer: boolean
    classic: boolean
  }
  followed: boolean
  rssFeedURL?: string
  rssFeedAtomURL?: string
}

export const UnknownSpeakerName = 'Unknown Speaker'
export const UnknownSpeakerID = 54697

export function SpeakerIsGeneric(speaker: SpeakerApi) {
  return ['Various Speakers', UnknownSpeakerName].includes(speaker.displayName)
}

export enum SpeakerFilterOption {
  Featured = 'featured',
  Choice = 'choice',
  Classic = 'classic',
  Reformer = 'reformer',
  Puritan = 'puritan',
}

export enum SpeakerSortOption {
  Newest = 'newest',
  SermonCount = 'sermon-count',
  Name = 'name',
}

export interface SpeakerRequestOptions extends ApiPaginationParams {
  collectionID?: number
  query?: string
  primarySpeakersOnly?: boolean
  broadcasterID?: string
  filterBy?: SpeakerFilterOption
  sortNameStartsWithLetter?: string
  randomize?: boolean
  sortBy?: SpeakerSortOption
}

export async function getSpeakerList(
  this: ApiClient,
  options: Partial<SpeakerRequestOptions>
): Promise<ApiPaginationResult<SpeakerApi>> {
  const { data } = await this.$axios.get(`node/discover/speakers`, {
    params: { ...options },
  })

  return data
}

export async function getSpeakerHighlightedList(
  this: ApiClient,
  options: Partial<SpeakerRequestOptions>
): Promise<ApiPaginationResult<SpeakerApi>> {
  const { data } = await this.$axios.get(`node/speakers/highlighted`, {
    params: { ...options },
  })
  return data
}

export async function getSpeaker(
  this: ApiClient,
  speakerID: number
): Promise<SpeakerApi> {
  const { data } = await this.$axios.get(`node/speakers/${speakerID}`)
  return data
}

export interface BroadcasterFromSpeaker {
  /* Lite Broadcaster Object */
  broadcaster: Record<string, any>
  sermonCount: number
  isPrimarySpeaker: boolean
}

export async function getBroadcastersFromSpeaker(
  this: ApiClient,
  speakerID: number
): Promise<BroadcasterFromSpeaker[]> {
  const { data } = await this.$axios.get(
    `node/speakers/${speakerID}/broadcasters`
  )
  return data.results as BroadcasterFromSpeaker[]
}

export async function createSpeaker(
  this: ApiClient,
  speakerName: string
): Promise<SpeakerApi> {
  const { data } = await this.$axios.post(`node/speakers`, {
    speakerName,
  })
  return data
}
