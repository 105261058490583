import { Context } from '@nuxt/types'
import Vue from 'vue'
import {
  ApiAccessCookie,
  ApiRefreshCookie,
  DeleteCookie,
  GetCookie,
  GetServerCookie,
  LinkedBroadcasterCookie,
  SetCookie,
  UseVueDashboardCookie,
  VueAccessCookie,
  VueRefreshCookie,
} from '~/assets/ts/utils/cookies'
import { StringByEnv } from '~/assets/ts/utils/environment'
import { ApiClient, ApiPaginationResult } from '~/apiclient/apiclient'
import { SpeakerApi, SpeakerIsGeneric } from '~/apiclient/apispeakers'
import { PromiseAll } from '~/assets/ts/utils/misc'
import { SermonCountsApi } from '~/apiclient/apibroadcasters'
import { clampArray } from '~/assets/ts/utils/lists'
import { modifyQs, qsValue } from '~/assets/ts/utils/params'
import { sendMessageToIframeParent } from '~/assets/ts/utils/iframe'
import { dashboardUrl } from '~/assets/ts/utils/urls'
import { BroadcasterRoles } from '~/models/users/roles'
import { SelectSpeakersList } from '~/components/site/speaker/Selector.vue'

export function DjangoHostUrl(): string {
  return StringByEnv(
    'http://localhost:3001',
    'https://embed.sermonaudio.com',
    'https://www.sermonaudio.com',
    'https://sermon-editor.review.sermonaudio.com' // 'https://embed.sermonaudio.com' | 'https://django-rename-dashboard.review.sermonaudio.com'
  )
}

export function DjangoUrl(path = '/') {
  return `${DjangoHostUrl()}${path}`
}

export function DashboardQs(context: Context | Record<string, any>) {
  return qsValue(context, 'dashboard')
}

export function AddDashboardQs(
  context: Context | Record<string, any>,
  path: string,
  qs: 'next' | 'dashboard'
) {
  const value = DashboardQs(context)
  if (!value) return path
  return `${path}?${qs}=${value}`
}

export function SetUseVueDashboard(use: boolean) {
  if (use) {
    SetCookie(UseVueDashboardCookie, '1')
  } else {
    DeleteCookie(UseVueDashboardCookie)
  }
}

export function GetUseVueDashboard() {
  return !!GetCookie(UseVueDashboardCookie)
}

export function SelectBroadcaster(
  id: string,
  path = undefined as string | undefined,
  vue?: Vue
) {
  // Set the broadcaster ID cookie so django knows which one to use
  SetCookie(LinkedBroadcasterCookie, id)

  // Set global token cookies equal to the value of the vue tokens
  // to deal with potential inconsistencies with vue branch logins
  SetCookie(ApiAccessCookie, GetCookie(VueAccessCookie))
  SetCookie(ApiRefreshCookie, GetCookie(VueRefreshCookie))

  if (path === undefined) return
  const useVue = GetUseVueDashboard()
  path = dashboardUrl(path, !useVue)
  if (vue && useVue) {
    vue.$navigateTo(path)
  } else {
    location.href = DjangoUrl(path)
  }
}

export interface SpeakersForBroadcasters {
  /** Speakers sorted by recency */
  recent: SpeakerApi[]
  /** Speakers sorted by sermon count that are not in recent list */
  frequent: SpeakerApi[]
}

export function AddedSpeakersLists(
  vue: Vue,
  speakers: SpeakersForBroadcasters | undefined
): SelectSpeakersList[] {
  if (!speakers || !speakers) return []
  return [
    {
      category: vue.$t('Most Frequent Speakers'),
      speakers: speakers.frequent,
      metadataType: 'sermonCount',
    },
    {
      category: vue.$t('Recent Speakers'),
      speakers: speakers.recent,
      metadataType: 'date',
    },
  ]
}

export function BuildSpeakersForBroadcaster(
  speakers: SpeakerApi[],
  counts: SermonCountsApi[],
  listsLength = 5
): SpeakersForBroadcasters {
  // remove generic and update counts
  const updatedSpeakers = [] as SpeakerApi[]
  speakers
    .filter((s: SpeakerApi) => !SpeakerIsGeneric(s))
    .forEach((speaker: SpeakerApi) => {
      const speakerCounts = counts.find(
        (c: SermonCountsApi) => c.speaker === speaker.displayName
      ) as SermonCountsApi | undefined
      if (speakerCounts) {
        speaker.sermonCount = speakerCounts.count
      }
      updatedSpeakers.push(speaker)
    })

  // split into lists
  let recent = [...updatedSpeakers].sort((a, b) => {
    const lastA = a.mostRecentPreachDate as string
    const lastB = b.mostRecentPreachDate as string
    return new Date(lastA).getTime() > new Date(lastB).getTime() ? -1 : 1
  })
  let frequent = [...updatedSpeakers].sort((a, b) =>
    a.sermonCount > b.sermonCount ? -1 : 1
  )

  // clamp to max listLength
  recent = clampArray(recent, listsLength)
  frequent = clampArray(
    frequent.filter((s) => !recent.includes(s)),
    listsLength
  )
  return {
    frequent,
    recent,
  }
}

export async function SpeakersForBroadcaster(
  apiClient: ApiClient,
  broadcasterID: string,
  listsLength = 5
): Promise<SpeakersForBroadcasters> {
  // get counts and speakers
  const [speakers, counts]: [
    ApiPaginationResult<SpeakerApi>,
    ApiPaginationResult<SermonCountsApi>,
  ] = await PromiseAll([
    apiClient.speakersForBroadcasters(broadcasterID),
    apiClient.speakerSermonCountsForBroadcaster(broadcasterID),
  ])
  return BuildSpeakersForBroadcaster(
    speakers.results,
    counts.results,
    listsLength
  )
}

export async function navigateToDashboardPage(
  context: Vue,
  url: string,
  django: boolean
) {
  url = dashboardUrl(url, django)
  if (django) {
    sendMessageToIframeParent('navigate', url)
  } else {
    await context.$navigateTo({
      path: url,
    })
  }
}

/** Update current route query string with the specified value(s) */
export function modifyDashboardQs(
  context: Vue,
  qs: Record<string, string | undefined>
) {
  const str = modifyQs(context, qs)
  sendMessageToIframeParent('qs', str)
  return str
}

/** Used in middleware to check if a user can edit the current page */
export function CanEditPage(
  context: Context,
  broadcasterID: string,
  role: BroadcasterRoles = BroadcasterRoles.Uploader
) {
  if (broadcasterID !== GetServerCookie(LinkedBroadcasterCookie, context)) {
    const user = context.$users.user
    if (user?.admin || user?.canEditBroadcaster(broadcasterID, role)) {
      const next = context.route?.fullPath.replace('?', '&')
      const qs = `?broadcasterID=${broadcasterID}&next=${next}`
      const url = `${dashboardUrl('switch', false)}${qs}`
      const django = next?.includes('django')
      context.$redirectTo(django ? `${url}&popout=true` : url)
    } else {
      context.error({
        statusCode: 503,
        message: `This page is unavailable with the current permissions`,
      })
    }
  }
}
