import { Context } from '@nuxt/types'
import { CanEditPage } from '~/assets/ts/utils/dashboard'
import {
  GetServerCookie,
  LinkedBroadcasterCookie,
} from '~/assets/ts/utils/cookies'
import { Broadcaster } from '~/models/broadcaster'
import { dashboardUrl } from '~/assets/ts/utils/urls'

export default async function (context: Context) {
  const broadcasterID = GetServerCookie(LinkedBroadcasterCookie, context)
  CanEditPage(context, broadcasterID)
  const { $apiClient, i18n, $redirectTo } = context
  const broadcaster = await $apiClient.getBroadcaster(broadcasterID)
  const { sermonID } = await $apiClient.createSermon(
    new Broadcaster(broadcaster),
    i18n.locale
  )
  return $redirectTo(dashboardUrl(`sermons/${sermonID}`, false))
}
