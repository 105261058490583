
import Vue, { PropType } from 'vue'
import SaLink from '~/components/_general/SaLink.vue'

export type ButtonSize = 'small' | 'normal' | 'large'
export type ButtonColor =
  | 'blue'
  | 'white'
  | 'red'
  | 'green'
  | 'gray'
  | 'gray-selected'
  | 'gray-light'
  | 'admin'

export const BaseButtonProps = {
  // Use for SaLink type buttons
  to: {
    type: String,
    default: undefined,
  },
  href: {
    type: String,
    default: undefined,
  },
  // Applies standard disable style for reactivity
  disabled: {
    type: Boolean,
  },
  // Use outline style for button
  outline: {
    type: Boolean,
    default: false,
  },
  // Color for buttons (both outline and solid)
  buttonColor: {
    type: String as PropType<ButtonColor>,
    default: 'blue',
  },
  // Use 'submit' type for forms
  isSubmit: {
    type: Boolean,
  },
  size: {
    type: String as PropType<ButtonSize>,
    default: 'normal',
  },
  noStyle: {
    type: Boolean,
  },
  newTab: {
    type: Boolean,
  },
  relative: {
    type: Boolean,
  },
  visualOnly: {
    type: Boolean,
  },
}

export default Vue.extend({
  name: 'SiteButton',
  props: {
    ...BaseButtonProps,
  },
  computed: {
    links(): Record<string, string | undefined> {
      if (this.to) {
        return {
          to: this.to,
        }
      } else if (this.href) {
        return {
          href: this.href,
          target: this.newTab ? '_blank' : undefined,
        }
      }
      return {}
    },
    componentType(): any {
      if (this.visualOnly) {
        return 'span'
      } else if (this.to) {
        return SaLink
      } else if (this.href) {
        return 'a'
      } else {
        return 'button'
      }
    },
    buttonColors(): string {
      if (this.outline) {
        switch (this.buttonColor) {
          case 'gray':
            return 'text-gray hover:!bg-gray-200 dark:hover:!bg-gray-700'
          case 'red':
            return 'text-red hover:!bg-red-light dark:hover:!bg-red-dark'
          case 'white':
            return 'text-white !bg-black !bg-opacity-0 hover:!bg-opacity-30 dark:hover:!bg-opacity-30'
          // return 'text-white !bg-black !bg-opacity-40 !dark:bg-opacity-40 hover:!bg-opacity-60 dark:hover:!bg-opacity-60'
          case 'admin':
            return 'text-admin hover:!bg-admin-light dark:hover:!bg-admin-dark'
          default:
            return 'text-blue hover:!bg-blue-light dark:hover:!bg-blue-navy'
        }
      } else {
        switch (this.buttonColor) {
          // TODO: confirm gray and red colors since they are not used yet
          case 'gray':
            return 'bg-gray-300 hover:!bg-gray-400 dark:bg-gray-800 dark:hover:!bg-gray-650'
          case 'gray-light':
            return 'bg-gray-300 hover:!bg-gray-200 dark:bg-gray-600 dark:hover:!bg-gray-500'
          case 'gray-selected':
            return 'text-white bg-gray-600 dark:text-black dark:bg-gray-300'
          case 'green':
            return 'bg-green hover:bg-green-dark text-white'
          case 'red':
            return 'bg-red hover:bg-red-dark text-white'
          case 'white':
            return 'bg-white hover:bg-gray-300 text-black'
          case 'admin':
            return 'bg-admin hover:bg-admin-dark text-white'
          default:
            return 'bg-blue hover:bg-blue-dark text-white'
        }
      }
    },
    disabledButtonColors(): string {
      if (this.outline) return ''
      switch (this.buttonColor) {
        case 'gray-light':
          return '!bg-gray-200 dark:!bg-gray-600 dark:!text-gray-800'
        default:
          return '!bg-gray-300 dark:!bg-gray-500 dark:!text-gray-800'
      }
    },
    // Sets button classes according to style/override
    buttonClasses(): string {
      if (this.noStyle) return ''

      let sizeClasses
      /** If these heights change, be sure to change the IconCircleButton as well */
      switch (this.size) {
        case 'small':
          sizeClasses = 'text-sm h-7'
          break
        case 'normal':
          sizeClasses = 'text-sm h-8'
          break
        case 'large':
          sizeClasses = 'h-10'
          break
      }

      let base = `flex items-center justify-center button-obround !no-underline ${sizeClasses} ${this.buttonColors}`
      if (this.outline) base += ' button-outlined'
      if (this.disabled) base += ` button-disabled ${this.disabledButtonColors}`
      return base
    },
  },
})
