import { ActionTree, GetterTree, MutationTree } from 'vuex'
import {
  BroadcasterApi,
  BroadcasterBannerApi,
  BroadcasterInclude,
  BroadcasterPrivateDataApi,
  FacebookGroupAccount,
} from '~/apiclient/apibroadcasters'
import { logError } from '~/assets/ts/utils/misc'
import { ApiPaginationResult } from '~/apiclient/apiclient'
import {
  SermonConfig,
  SermonEditorDropdownContents,
} from '~/apiclient/apisermoneditor'

export const state = () => ({
  broadcaster: undefined as BroadcasterApi | undefined,
  broadcasterPrivateData: undefined as BroadcasterPrivateDataApi | undefined,
  facebookAccounts: undefined as FacebookGroupAccount[] | undefined,
  bannerGallery: {
    next: undefined,
    nodeType: '',
    nodeDisplayName: '',
    totalCount: 0,
    results: [],
  } as ApiPaginationResult<BroadcasterBannerApi>,
  sermonEditorDropdownContents: undefined as
    | SermonEditorDropdownContents
    | undefined,
  sermonConfig: undefined as SermonConfig | undefined,
  apiKey: '',
})

export type DashboardState = ReturnType<typeof state>

export const mutations: MutationTree<DashboardState> = {
  ADD_BROADCASTER: (state: DashboardState, broadcaster: BroadcasterApi) => {
    if (state.broadcaster?.broadcasterID !== broadcaster.broadcasterID) {
      // new broadcaster selected
      state.apiKey = ''
    }
    state.broadcaster = broadcaster
  },
  ADD_PRIVATE_DATA: (
    state: DashboardState,
    privateData: BroadcasterPrivateDataApi
  ) => {
    state.broadcasterPrivateData = privateData
  },
  ADD_BANNERS: (
    state: DashboardState,
    banners: ApiPaginationResult<BroadcasterBannerApi>
  ) => {
    banners.results = [...state.bannerGallery.results, ...banners.results]
    state.bannerGallery = banners
  },
  ADD_SERMON_EDITOR_DROPDOWN_CONTENTS: (
    state: DashboardState,
    contents: SermonEditorDropdownContents
  ) => {
    state.sermonEditorDropdownContents = contents
  },
  ADD_SERMON_CONFIG: (state: DashboardState, config: SermonConfig) => {
    state.sermonConfig = config
  },
  SET_BROADCASTER_API_KEY: (state: DashboardState, key: string) => {
    state.apiKey = key
  },
  SET_FACEBOOK_ACCOUNTS: (
    state: DashboardState,
    accounts: FacebookGroupAccount[]
  ) => {
    state.facebookAccounts = accounts
  },
  CLEAR_FACEBOOK_ACCOUNTS: (state: DashboardState) => {
    state.facebookAccounts = undefined
  },
}

export const getters: GetterTree<DashboardState, DashboardState> = {
  broadcaster: (state) => state.broadcaster,
  broadcasterPrivateData: (state) => state.broadcasterPrivateData,
  bannerGallery: (state) => state.bannerGallery,
  sermonEditorDropdownContents: (state) => state.sermonEditorDropdownContents,
  sermonConfig: (state) => state.sermonConfig,
  apiKey: (state) => state.apiKey,
  facebookAccounts: (state) => state.facebookAccounts,
}

export const actions: ActionTree<DashboardState, DashboardState> = {
  async fetchBroadcaster({ commit }, { broadcasterID }) {
    try {
      /** TODO: remove bypassCache once cacheTTL is fully supported via the API */
      const data = await this.$apiClient.getBroadcaster(broadcasterID, {
        lite: false,
        bypassCache: true,
        cacheTTL: 0,
        include: BroadcasterInclude.All,
      })

      commit('ADD_BROADCASTER', data)
    } catch (e) {
      logError(e)
    }
  },
  async fetchBroadcasterApiKey({ state, commit }, { broadcasterID }) {
    if (state.apiKey && broadcasterID === state.broadcaster?.broadcasterID) {
      return
    }
    try {
      const { key } = await this.$apiClient.getBroadcasterApiKey(broadcasterID)
      commit('SET_BROADCASTER_API_KEY', key)
    } catch (e) {
      logError(e)
    }
  },
  async fetchBroadcasterPrivateData({ commit }, { broadcasterID }) {
    try {
      const data = await this.$apiClient.broadcasterPrivateData(broadcasterID)
      commit('ADD_PRIVATE_DATA', data)
    } catch (e) {
      logError(e)
    }
  },
  async fetchSermonEditorDropdownContents({ commit }, { sermonID }) {
    try {
      const data = await this.$apiClient.sermonEditorDropdownContents(sermonID)
      commit('ADD_SERMON_EDITOR_DROPDOWN_CONTENTS', data)
    } catch (e) {
      logError(e)
    }
  },
  async fetchSermonConfig({ commit, getters }) {
    if (getters.sermonConfig) return
    try {
      const data = await this.$apiClient.sermonConfig()
      commit('ADD_SERMON_CONFIG', data)
    } catch (e) {
      logError(e)
    }
  },
  async fetchFacebookAccounts({ commit, getters }, broadcasterID) {
    if (getters.facebookAccounts) return
    try {
      const data =
        await this.$apiClient.getBroadcasterFacebookAccounts(broadcasterID)
      commit('SET_FACEBOOK_ACCOUNTS', data)
    } catch (e) {
      logError(e)
    }
  },
}
