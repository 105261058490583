import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'
import { boolOrUndefined } from '~/assets/ts/utils/misc'
import { SiteUserRequiredModal } from '~/assets/ts/utils/site'
import { MediaApi } from '~/apiclient/apisermons'
import { LanguageApi } from '~/apiclient/apifilters'

export type LanguageCode3 = 'eng' | string

export interface CaptionLanguages {
  alpha_2: string[]
  alpha_3: LanguageCode3[]
}

export type VideoCodecType = string
export type AudioCodecType = string

export type MediaDownloadType =
  | 'audio'
  | 'video'
  | 'text'
  | 'transcript'
  | 'caption'
  | 'all'

export interface MediaDownloadOptions {
  raw?: boolean
  type?: MediaDownloadType
  language?: LanguageApi
}

export enum MediaType {
  MP3 = 'mp3',
  AAC = 'aac',
  MP4 = 'mp4',
  PDF = 'pdf',
  Word = 'doc',
  Transcript = 'transcript',
  Jpeg = 'jpg',
  OriginalAudio = 'orig-audio',
  OriginalVideo = 'orig-video',
  Caption = 'caption',
}

export class Media {
  type: MediaType
  live: boolean
  language: string
  streamURL?: string
  eventStreamURL?: string
  downloadURL?: string
  bitrate?: number
  duration?: number
  audioCodec?: AudioCodecType
  videoCodec?: VideoCodecType
  thumbnailImageURL?: string
  adaptive: boolean
  fileSizeBytes?: number
  autoGenerated?: boolean
  sermonID: string

  constructor(media: MediaApi, sermonID: string) {
    this.type = media.mediaType
    this.live = media.live
    this.adaptive = media.adaptiveBitrate
    this.language = media.language
    this.sermonID = sermonID

    this.fileSizeBytes = media.fileSizeBytes || undefined
    this.streamURL = media.streamURL || undefined
    this.eventStreamURL = media.eventStreamURL || undefined
    this.downloadURL = media.downloadURL || undefined
    this.bitrate = media.bitrate || undefined
    this.duration = media.duration || undefined
    this.audioCodec = media.audioCodec || undefined
    this.videoCodec = media.videoCodec || undefined
    this.thumbnailImageURL = media.thumbnailImageURL || undefined
    this.autoGenerated = boolOrUndefined(media.autoGenerated)
  }

  get rawURL(): string | undefined {
    return this.streamURL ? `${this.streamURL}&stats=false` : undefined
  }

  get rawDownloadURL(): string | undefined {
    return this.downloadURL ? `${this.downloadURL}&stats=false` : undefined
  }

  downloadOrRaw(raw: boolean): string | undefined {
    return raw ? this.downloadURL : this.rawDownloadURL
  }

  async downloadTranscript(context: Vue) {
    if (context.$users.loggedIn) {
      await context.$apiClient.downloadSermonTranscript(
        this.sermonID,
        this.language
      )
    } else {
      SiteUserRequiredModal(context, context.$t('Download Transcript'))
    }
  }
}

export class MediaSet {
  text: [Media]
  audio: [Media]
  video: [Media]
  caption: [Media]

  constructor(mediaSet: any, sermonID: string) {
    this.caption = mediaSet.caption
      ? mediaSet.caption.map((c: any) => new Media(c, sermonID))
      : []
    this.text = mediaSet.text.map((t: any) => new Media(t, sermonID))
    this.audio = mediaSet.audio.map((a: any) => new Media(a, sermonID))
    this.video = mediaSet.video.map((v: any) => new Media(v, sermonID))
  }

  /**
   * @private
   */
  getVideoTitle(videoResolution: string): string {
    if (process.client) {
      return window.$nuxt
        .$t('{videoResolution} Video', { videoResolution })
        .toString()
    } else {
      return `${videoResolution} Video`
    }
  }

  getDownloadList(
    vue: Vue,
    options: MediaDownloadOptions = {}
  ): MediaDownload[] {
    const list = [] as MediaDownload[]
    const raw = options.raw ?? false
    const type = options.type ?? 'all'
    const all = type === 'all'
    const video = all || type === 'video'
    if (video && this.video1080p) {
      list.push(
        new MediaDownload(
          this.getVideoTitle('1080p'),
          this.video1080p.downloadOrRaw(raw)
        )
      )
    }
    if (video && this.video720p) {
      list.push(
        new MediaDownload(
          this.getVideoTitle('720p'),
          this.video720p.downloadOrRaw(raw)
        )
      )
    }
    if (video && this.video360p) {
      list.push(
        new MediaDownload(
          this.getVideoTitle('360p'),
          this.video360p.downloadOrRaw(raw)
        )
      )
    }

    const audio = all || type === 'audio'
    if (audio && this.audioHigh) {
      list.push(
        new MediaDownload(vue.$t('HQ Audio'), this.audioHigh.downloadOrRaw(raw))
      )
    }
    if (audio && this.audioLow) {
      list.push(
        new MediaDownload(vue.$t('Audio'), this.audioLow.downloadOrRaw(raw))
      )
    }
    if ((all || type === 'text') && this.pdf) {
      list.push(new MediaDownload('PDF', this.pdf.downloadOrRaw(raw)))
    }

    if (this.caption.length > 0) {
      if (all || type === 'transcript') {
        const caption = this.caption[0]
        let title = vue.$t('Raw Transcript')
        if (caption.autoGenerated) title += ` (${vue.$t('Auto')})`
        list.push(new MediaDownload(title, undefined, caption))
      }
      // Captions aren't included in "all" as they are not public
      const languageApi = options.language
      if (type === 'caption' && languageApi) {
        const caption = this.caption.find(
          (c) => c.language === languageApi.languageCode3
        )
        if (caption) {
          const language = languageApi.localizedName
          const captionTitle = vue.$t('{language} Caption', {
            language,
          })
          list.push(new MediaDownload(captionTitle, caption.downloadOrRaw(raw)))

          const transcriptTitle = vue.$t('{language} Transcript', {
            language,
          })
          list.push(new MediaDownload(transcriptTitle, undefined, caption))
        }
      }
    }
    return list
  }

  highestVideo(includeH265 = true): Media | undefined {
    return this.video
      .filter((v) => !v.adaptive && (includeH265 || v.videoCodec !== 'h265'))
      .sort((a, b) => (b.bitrate ?? 0) - (a.bitrate ?? 0))[0]
  }

  // TODO: set this up with a valid set of languages
  getTranscript(languageCode3: LanguageCode3 | undefined): Media | undefined {
    if (!this.caption?.length) return undefined
    if (!languageCode3) {
      return this.caption[0]
    }
    return (
      this.caption.find((c) =>
        c.downloadURL?.includes(`-${languageCode3}.srt`)
      ) ?? this.caption[0]
    )
  }

  get videoAdaptive(): Media | undefined {
    return this.video.find((quality) => quality.adaptive)
  }

  private getVideo(keyword: string): Media | undefined {
    return this.video.find(
      (media) =>
        !media.adaptive &&
        media.downloadURL
          ?.toLowerCase()
          .includes(`video/${keyword.toLowerCase()}`)
    )
  }

  get videoH265(): Media | undefined {
    return this.video.find((media) => media.videoCodec === 'h265')
  }

  get video1080p(): Media | undefined {
    return this.getVideo('1080p')
  }

  get video720p(): Media | undefined {
    return this.getVideo('high')
  }

  get video360p(): Media | undefined {
    return this.getVideo('low')
  }

  get highestAudio(): Media | undefined {
    return this.audioHigh || this.audioLow
  }

  get audioHigh(): Media | undefined {
    return this.audio.length > 1 ? this.audio[0] : undefined
  }

  get audioLow(): Media | undefined {
    return this.audio[this.audio.length - 1]
  }

  get pdf(): Media | undefined {
    return this.text.find((t) => t.downloadURL?.includes('.pdf'))
  }
}

export class MediaDownload {
  title: string
  url?: string
  media?: Media

  constructor(title: TranslateResult, url?: string, media?: Media) {
    this.title = title.toString()
    this.url = url
    this.media = media
  }
}
