
import Vue, { PropType } from 'vue'
import { SaIconsType } from '~/assets/ts/types/icons'
import SaIcon from '~/components/_general/SaIcon.vue'

export default Vue.extend({
  name: 'InlineIcon',
  components: { SaIcon },
  props: {
    icon: {
      type: String as PropType<SaIconsType>,
      required: true,
    },
    slotClasses: {
      type: String,
      default: '',
    },
    iconClasses: {
      type: String,
      default: '',
    },
    smallGap: {
      type: Boolean,
    },
    gapSize: {
      type: String as PropType<'none' | 'xs' | 'sm' | 'md' | 'lg'>,
      default: 'md',
    },
    flipSide: {
      type: Boolean,
    },
    hideIcon: {
      type: Boolean,
    },
  },
  computed: {
    gapClass(): string {
      switch (this.gapSize) {
        case 'none':
          return ''
        case 'xs':
          return 'gap-x-0.5'
        case 'sm':
          return 'gap-x-1'
        case 'lg':
          return 'gap-x-3'
        case 'md':
        default:
          return 'gap-x-2'
      }
    },
  },
})
