
import DatePicker from 'vue2-datepicker'
import Vue, { PropType } from 'vue'
import 'vue2-datepicker/index.css'
import { TranslateResult } from 'vue-i18n'
import {
  addDaysToDate,
  datePickerStartDate,
  daysBetweenDates,
  jsDateToApiDateStr,
  localizeDateTime,
} from '~/assets/ts/utils/date'
import SiteButton from '~/components/site/Button.vue'
import { DateFormat } from '~/assets/ts/enums'
import SaIcon from '~/components/_general/SaIcon.vue'
import DropdownArrow from '~/components/_general/DropdownArrow.vue'

type DatePickerType = 'date' | 'datetime' | 'year' | 'month' | 'time' | 'week'
type DatePickerValueType = 'format' | 'date' | 'timestamp'
type DatePickerFormat =
  | 'YYYY-MM-DD'
  | 'MMMM D, YYYY'
  | 'MMM D, YYYY, hh:mm a'
  | undefined
type DatePickerShortcuts = 'stats' | 'date' | ''

export type DatePickerDate = Date | Date[] | undefined

export interface DatePickerShortcut {
  title: TranslateResult
  dates: DatePickerDate
}

// https://github.com/mengxiong10/vue2-datepicker
// https://mengxiong10.github.io/vue2-datepicker/index.html
export default Vue.extend({
  name: 'DateInput',
  components: { DropdownArrow, SaIcon, SiteButton, DatePicker },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: [Array, Date] as PropType<DatePickerDate>,
      default: undefined,
    },
    pickerType: {
      type: String as PropType<DatePickerType>,
      default: 'date',
    },
    valueType: {
      type: String as PropType<DatePickerValueType>,
      default: 'date',
    },
    format: {
      type: String as PropType<DatePickerFormat>,
      default: 'MMMM D, YYYY',
    },
    placeholder: {
      type: String as PropType<TranslateResult>,
      default: '',
    },
    range: {
      type: Boolean,
    },
    maxRangeDays: {
      type: Number,
      default: undefined,
    },
    multiple: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    clearable: {
      type: Boolean,
    },
    disabledDates: {
      type: Array as PropType<Date[]>,
      default: () => {
        return []
      },
    },
    whitelist: {
      type: Array as PropType<Date[]>,
      default: () => {
        return []
      },
    },
    firstDate: {
      type: Date as PropType<Date>,
      default: undefined,
    },
    lastDate: {
      type: Date as PropType<Date>,
      default: undefined,
    },
    /** Whether to allow manual input */
    editable: {
      type: Boolean,
    },
    shortcuts: {
      type: String as PropType<DatePickerShortcuts>,
      default: '',
    },
    use12h: {
      type: Boolean,
      default: true,
    },
    minuteStep: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      rangeStart: undefined as Date | undefined,
      isOpen: undefined as boolean | undefined,
      openTime: 0,
    }
  },
  computed: {
    now(): Date {
      return new Date()
    },
    shortcutOptions(): DatePickerShortcut[] {
      switch (this.shortcuts) {
        case 'stats':
          return this.statsShortcuts
        case 'date':
          return this.dateShortcuts
        default:
          return []
      }
    },
    statsShortcuts(): DatePickerShortcut[] {
      return [
        this.shortcutMonth(0),
        this.shortcutMonth(1),
        this.shortcutPastXDays(90),
        this.shortcutYear(0),
        this.shortcutYear(1),
      ]
    },
    dateShortcuts(): DatePickerShortcut[] {
      return [this.shortcutToday()]
    },
    rangeSeparator(): string {
      if (this.multiple) return ', '
      return ' - '
    },
    language(): DatePicker.Lang | undefined {
      // https://github.com/mengxiong10/vue-datepicker-next/blob/main/locale/en.js
      switch (this.$i18n.locale) {
        case 'zh': {
          return require('vue2-datepicker/locale/zh-cn.js')
        }
        case 'de': {
          return require('vue2-datepicker/locale/de.js')
        }
        case 'it': {
          return require('vue2-datepicker/locale/it.js')
        }
        case 'ko': {
          return require('vue2-datepicker/locale/ko.js')
        }
        case 'pt': {
          return require('vue2-datepicker/locale/pt.js')
        }
        case 'es': {
          return require('vue2-datepicker/locale/es.js')
        }
        case 'fr': {
          return require('vue2-datepicker/locale/fr.js')
        }
        case 'en':
        case 'gb':
        case 'km':
        default: {
          return require('vue2-datepicker/locale/en.js')
        }
      }
    },
    disabledStrings(): string[] {
      return this.disabledDates.map((date) => jsDateToApiDateStr(date))
    },
    whitelistStrings(): string[] {
      return this.whitelist.map((date) => jsDateToApiDateStr(date))
    },
    defaultDate(): Date | undefined {
      if (!this.firstDate || !this.lastDate) return undefined
      // Iterate through each date starting from 'start' until 'end'
      const currentDate = new Date(this.firstDate)
      while (currentDate <= this.lastDate) {
        // Check if the current date is not disabled
        if (!this.dateIsDisabled(currentDate)) return currentDate
        // Move to the next day
        currentDate.setDate(currentDate.getDate() + 1)
      }
      // No valid date found
      return undefined
    },
    firstDateMidnight(): Date | undefined {
      if (!this.firstDate) return undefined
      const date = new Date(this.firstDate)
      date.setHours(0, 0, 0, 0)
      return date
    },
  },
  methods: {
    dateIsDisabled(date: Date): boolean {
      const dateStr = jsDateToApiDateStr(date)
      if (this.whitelistStrings.includes(dateStr)) return false
      if (this.disabledStrings.includes(dateStr)) return true
      if (this.firstDateMidnight && this.firstDateMidnight > date) return true
      if (this.lastDate && this.lastDate < date) return true
      if (!this.range) return false
      const start = this.rangeStart
      if (!start) return false
      const max = this.maxRangeDays
      return !!(max && daysBetweenDates(start, date) > max)
    },
    inputEvent(event: DatePickerDate) {
      this.$emit('input', event)
    },
    openEvent() {
      this.rangeStart = undefined
      this.openTime = Date.now()
      this.isOpen = true
    },
    changeEvent(event: DatePickerDate) {
      this.$emit('change', event)
    },
    pickEvent(event: DatePickerDate) {
      if (!this.range || this.rangeStart) return
      this.rangeStart = datePickerStartDate(event)
    },
    shortcutPastXDays(dayCount: number): DatePickerShortcut {
      return {
        title: this.$t('Past {n} Days', { n: dayCount }),
        dates: [addDaysToDate(this.now, -dayCount), this.now],
      }
    },
    shortcutToday(): DatePickerShortcut {
      return {
        title: this.$t('Today'),
        dates: new Date(),
      }
    },
    shortcutYear(yearsPrevious = 0): DatePickerShortcut {
      const year = this.now.getFullYear() - yearsPrevious
      const yearDate = new Date(year, 0, 1)
      const endDate = new Date(year, 11, 31)
      return {
        title: year.toString(),
        dates: [yearDate, this.clampDate(endDate)],
      }
    },
    shortcutMonth(monthsPrevious = 0): DatePickerShortcut {
      const date = new Date()
      date.setMonth(date.getMonth() - monthsPrevious)
      date.setDate(1)
      const endDate = new Date(date)
      endDate.setMonth(date.getMonth() + 1)
      endDate.setDate(0)
      return {
        title: localizeDateTime(date, DateFormat.YearMonth),
        dates: [date, this.clampDate(endDate)],
      }
    },
    applyShortcut(
      shortcut: DatePickerShortcut,
      emit: (dates: DatePickerDate) => void
    ) {
      emit(shortcut.dates)
      this.isOpen = false
    },
    clampDate(date: Date) {
      return date > this.now ? this.now : date
    },
    close() {
      if (!this.isOpen || !this.openTime) return
      // If we just opened the picker, assume we don't want to immediately close it
      if (Date.now() - this.openTime < 300) return
      this.isOpen = false
    },
    closedEvent() {
      this.openTime = 0
      this.isOpen = undefined
    },
  },
})
