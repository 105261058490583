
import Vue from 'vue'

export default Vue.extend({
  name: 'Poller',
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    /** Will add debug logs if title is included **/
    debugTitle: {
      type: String,
      default: '',
    },
    /** Update every x seconds **/
    updateSeconds: {
      type: Number,
      default: 60,
    },
    /** Update every x milliseconds **/
    updateMs: {
      type: Number,
      default: undefined,
    },
    /** Should poll be emitted when the poller is set up **/
    pollOnActivate: {
      type: Boolean,
      default: true,
    },
    /** Indicates that we should poll on mount IF pollOnActivate is also set **/
    pollOnMount: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      startTime: undefined as number | undefined,
      timeout: undefined as number | undefined,
    }
  },
  computed: {
    ms(): number {
      return this.updateMs ?? this.updateSeconds * 1000
    },
  },
  watch: {
    active() {
      this.setup(this.pollOnActivate)
    },
    ms: {
      handler(newValue: number, oldValue: number) {
        this.speedChange(newValue, oldValue)
      },
    },
  },
  mounted() {
    if (!this.active) return
    this.setup(this.pollOnActivate && this.pollOnMount)
  },
  destroyed() {
    this.cancel()
  },
  methods: {
    speedChange(newValue: number, oldValue: number) {
      if (newValue === oldValue) return
      if (!this.ms) {
        this.cancel()
        this.log('canceled due to 0ms')
      } else {
        this.log(`speed change from ${oldValue}ms to ${newValue}ms`)
        const immediate =
          !!this.startTime && Date.now() - this.startTime > this.ms
        if (this.startTime) {
          this.log(`time remaining: ${Date.now() - this.startTime}ms`)
          if (immediate) {
            this.log(`polling immediately`)
          }
        }
        this.setup(immediate)
      }
    },
    cancel() {
      this.startTime = undefined
      clearTimeout(this.timeout)
    },
    setup(immediate: boolean) {
      if (!this.$isClient) return
      this.cancel()
      if (!this.active) {
        this.log('cleared')
        return
      }
      this.log('setup')
      if (immediate) {
        this.poll()
      } else {
        this.setTimeout()
      }
    },
    setTimeout() {
      this.startTime = Date.now()
      this.timeout = window.setTimeout(() => {
        this.poll()
      }, this.ms)
    },
    poll() {
      if (!this.active) return
      this.log('polling')
      this.$emit('poll')
      this.setTimeout()
    },
    log(text: string) {
      if (!this.debugTitle) return
      console.log(`${this.debugTitle} ${text}`)
    },
  },
})
