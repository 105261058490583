import { Context } from '@nuxt/types'
import { ArticleApi } from '~/apiclient/apiarticles'
import { CanEditPage } from '~/assets/ts/utils/dashboard'

export default async function (context: Context) {
  const params = context.params
  const store = context.store
  const route = context.route
  const articleID = params.articleID
  const broadcasterID = params.broadcasterID
  const slug = params.slug

  await store.dispatch('articles/fetchArticle', {
    articleID,
    broadcasterID,
    slug,
  })
  const article = store.getters['articles/article'] as ArticleApi | undefined
  if (article) {
    if (route.path.includes('dashboard')) {
      CanEditPage(context, article.broadcaster.broadcasterID)
    }
  } else {
    context.error({
      statusCode: 404,
      message: 'Article not found',
    })
  }
}
