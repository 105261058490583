// Modified version of this: https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
export function humanFileSize(
  bytes: number,
  decimalPoints = 2,
  dataRate = false
): string {
  const threshold = 1000
  if (Math.abs(bytes) < threshold) {
    return bytes + (dataRate ? 'bps' : ' B')
  }

  if (bytes < 1000000) {
    decimalPoints = 0
  }

  const units = dataRate
    ? ['KBps', 'MBps', 'GBps', 'TBps']
    : ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  let u = -1
  const r = 10 ** decimalPoints

  do {
    bytes /= threshold
    ++u
  } while (
    Math.round(Math.abs(bytes) * r) / r >= threshold &&
    u < units.length - 1
  )

  return (
    bytes.toFixed(decimalPoints) + (decimalPoints > 0 ? ' ' : '') + units[u]
  )
}

export function roundUp(value: number, roundTo = 50): number {
  return Math.ceil(value / roundTo) * roundTo
}

export function toDollars(cents: number, includeCents = true): string {
  const num = cents / 100
  let finalString
  if (includeCents) {
    finalString = num.toFixed(2)
  } else {
    finalString = num
  }

  return `$${finalString}`
}

export function randomInRange(min: number, max: number): number {
  return Math.random() * (max - min) + min
}

export function toCurrency(
  value: number,
  locale = 'en',
  currency = 'USD'
): string {
  return value.toLocaleString(locale, {
    style: 'currency',
    minimumFractionDigits: 0,
    currency,
  })
}

export function toOrdinal(
  context: Record<string, any>,
  number: number,
  lang: string | undefined = undefined
): string {
  lang = lang || context.$i18n.locale || 'en'
  const formatter = new Intl.PluralRules(lang, { type: 'ordinal' })
  const suffixes = {
    zero: context.$t('ordinal.suffix.zero'),
    one: context.$t('ordinal.suffix.one'),
    two: context.$t('ordinal.suffix.two'),
    few: context.$t('ordinal.suffix.few'),
    many: context.$t('ordinal.suffix.many'),
    other: context.$t('ordinal.suffix.other'),
  }
  const suffix = suffixes[formatter.select(number)]
  return number + suffix
}

export function clamp(number: number, min: number, max: number): number {
  return Math.min(Math.max(number, min), max)
}

export function abbreviateNumber(num: number, roundTo = 1): string {
  const abbreviations = ['', 'K', 'M', 'B', 'T']
  const absNum = Math.abs(num)
  let abbreviatedNum = ''

  if (absNum < 1000) {
    return num.toString() // No abbreviation needed
  }

  const exponent = Math.floor(Math.log10(absNum) / 3) // Determine the exponent (k, m, b, t)
  const roundedNum = num / Math.pow(1000, exponent) // Get the rounded number
  abbreviatedNum = roundedNum.toFixed(roundTo) // Round to decimal places

  // Append the abbreviation to the number
  abbreviatedNum += abbreviations[exponent]

  return abbreviatedNum
}

export type MultipleOfTen =
  | 10
  | 100
  | 1000
  | 10000
  | 100000
  | 1000000
  | 10000000

/**
 * Rounds a number up to the nearest 10, 100, 1000, 10000 and adds a "+" at the end
 * @example 74432 => 74,000+
 * */
export function roundToZeros(
  value: number,
  roundTo: MultipleOfTen = 100
): string {
  const num = Math.floor(value / roundTo) * roundTo
  return num !== 0 ? `${num.toLocaleString()}+` : ''
}

export function secondsToRoundMinutes(seconds: number) {
  return Math.round(seconds / 60)
}

export function isInt(value: number) {
  return Number.isInteger(value)
}

export function numOr10k(count: number) {
  return count === 10000 ? '10k+' : count.toLocaleString()
}

export function createNumberArray(max: number, step: number, start = 0) {
  const result = []
  for (let i = start; i <= max; i += step) {
    result.push(i)
  }
  return result
}
