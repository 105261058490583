import { DjangoHostUrl } from '~/assets/ts/utils/dashboard'

export interface IframeMessage {
  event?: string
  message?: any
  originalEvent: MessageEvent
}

function formatMessageEvent(event: MessageEvent): IframeMessage {
  try {
    return JSON.parse(event.data)
  } catch (_e) {
    return {
      event: undefined,
      message: undefined,
      originalEvent: event,
    }
  }
}

export function isInIframe(): boolean {
  return window && window !== window.top
}

export function isInPopup(): boolean {
  return window && !!window.opener
}

export function receiveIframeMessage(
  onReceipt: (message: IframeMessage) => void
) {
  if (!window) return
  window.addEventListener('message', (e) => {
    const iMessage = formatMessageEvent(e)
    if (iMessage.event === undefined) return
    onReceipt(iMessage)
  })
}

export function sendMessageToIframe(
  iframe: HTMLIFrameElement,
  event: string,
  message: any
) {
  return sendMessage(iframe.contentWindow, event, message)
}

export function sendMessageToIframeParent(event: string, message: any) {
  if (!window) return
  return sendMessage(window.top, event, message)
}

export function sendModalizeToIframeParent(enter: boolean) {
  if (!window) return
  return sendMessage(window.top, 'modalize', enter)
}

export function sendMessageToPopupParent(event: string, message: any) {
  if (!window) return
  return sendMessage(window.opener, event, message)
}

export function closePopup() {
  if (!isInPopup()) return
  window.close()
}

function sendMessage(w: Window | null, event: string, message: any) {
  if (!w) return
  console.debug('VUE 🡢 DJANGO', Date.now(), { event, message })
  w.postMessage(JSON.stringify({ event, message }), DjangoHostUrl())
}
