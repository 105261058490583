
import Vue, { PropType } from 'vue'
import CustomScroll from '~/components/_general/CustomScroll.vue'
import FocusVisibleElement from '~/components/_general/FocusVisibleElement.vue'
import { receiveIframeMessage } from '~/assets/ts/utils/iframe'
import { wait } from '~/assets/ts/utils/misc'

export type CustomDropdownDirection = 'auto' | 'down' | 'up'

export const CustomDropdownProps = {
  clickable: {
    type: Boolean,
    default: true,
  },
  animate: {
    type: Boolean,
  },
  direction: {
    type: String as PropType<CustomDropdownDirection>,
    default: 'auto',
  },
  contentClasses: {
    type: String,
    default: '',
  },
  openContentClasses: {
    type: String,
    default: '',
  },
  targetClasses: {
    type: String,
    default: '',
  },
  useTheme: {
    type: Boolean,
    default: true,
  },
  checkFocus: {
    type: Boolean,
  },
  left: {
    type: Boolean,
  },
  hover: {
    type: Boolean,
  },
  clickaway: {
    type: Boolean,
    default: true,
  },
  /** Clickaway event targets will be passed to this function to see if it's safe or not */
  clickawayChecker: {
    type: Function as PropType<(target: EventTarget) => boolean>,
    default: undefined,
  },
  scrollaway: {
    type: Boolean,
    default: true,
  },
  dropPosition: {
    type: Number,
    default: undefined,
  },
}

export default Vue.extend({
  name: 'CustomDropdown',
  components: { CustomScroll },
  inheritAttrs: false,
  props: {
    ...CustomDropdownProps,
  },
  data() {
    return {
      isActive: false,
      opened: false,
      contentHover: false,
      containerHeight: 0,
      buttonHeight: 0,
      leftAlign: this.left,
      dropUp: this.direction === 'up',
    }
  },
  computed: {
    dropPixels(): number {
      if (this.dropPosition !== undefined) return this.dropPosition
      return this.dropUp ? this.containerHeight : this.buttonHeight
    },
    position(): string {
      return this.dropUp ? 'up-position' : 'down-position'
    },
    leftSidebar(): number {
      return this.$store.getters['site/navOpen'] ? 240 : 0
    },
    componentType(): Record<any, any> | string {
      return this.checkFocus ? FocusVisibleElement : 'button'
    },
    dropdown(): HTMLElement | undefined {
      const d = this.$refs.dropdown as Vue | undefined
      return d?.$el as HTMLElement | undefined
    },
    container(): HTMLElement {
      return this.$refs.container as HTMLElement
    },
    button(): HTMLElement | undefined {
      if (!this.$refs.button) return undefined
      const button = this.$refs.button as Vue
      if (button.$el) return button.$el as HTMLElement
      return this.$refs.button as HTMLElement
    },
    clickableTargetClasses(): string {
      let classes = this.clickable ? 'cursor-pointer' : 'pointer-events-none'
      if (this.targetClasses) {
        classes += ' ' + this.targetClasses
      }
      return classes
    },
    useHover(): boolean {
      return this.hover && !this.$device.isMobileOrTablet
    },
    auto(): boolean {
      return this.direction === 'auto'
    },
  },
  destroyed() {
    this.$off('close', this.closeDropdown)
    this.$off('open', this.openDropdown)
    this.$off('scrollTop', this.scrollTop)
    this.$off('update', this.updateSizes)
    this.$eventBus.$off('closeDropdowns', this.closeDropdown)
    if (!this.scrollaway) return
    window.removeEventListener('scroll', this.onScroll)
  },
  mounted() {
    this.$on('close', this.closeDropdown)
    this.$on('open', this.openDropdown)
    this.$on('scrollTop', this.scrollTop)
    this.$on('update', this.updateSizes)
    this.$eventBus.$on('closeDropdowns', this.closeDropdown)
    this.setHeights()
    if (!this.scrollaway) return
    window.addEventListener('scroll', this.onScroll)
    if (!this.clickaway) return
    receiveIframeMessage((message) => {
      if (message.event === 'clickaway') {
        this.clickawayEvent()
      }
    })
  },
  methods: {
    scrollTop() {
      if (!this.dropdown) return
      this.dropdown.scrollTop = 0
    },
    onScroll(_event: Event) {
      if (this.$device.isMobileOrTablet) return
      if (!this.opened) return
      if (this.contentHover) return
      this.closeDropdown()
    },
    openDropdown() {
      if (this.isActive) return
      this.opened = true
      // Timeout is to keep the clickaway event from interfering
      setTimeout(() => {
        this.$emit('opened')
        this.isActive = true
        this.updateSizes(false)
      }, 1)
    },
    async updateSizes(delay = true) {
      if (!this.opened || !this.dropdown) return
      if (delay) await wait(1)
      const pageWidth = document.body.clientWidth
      const container = this.setHeights()
      this.setLeftAlign(pageWidth, container.left, container.right)
      this.setAutoUp(container.y)
    },
    setHeights(): DOMRect {
      const container = this.container.getBoundingClientRect()
      this.containerHeight = container.height
      this.buttonHeight = this.button?.clientHeight ?? container.height
      return container
    },
    setAutoUp(containerTop: number) {
      const height = this.dropdown?.getBoundingClientRect().height
      if (!this.auto || !height) return
      const topDistance = containerTop - height
      const bottomPos = containerTop + this.containerHeight + height
      const bottomDistance = window.innerHeight - bottomPos
      // Tries to go to the bottom unless we're within 20 pixels,
      // then it will go up if up has more distance than bottom
      this.dropUp = bottomDistance < 20 ? topDistance > bottomDistance : false
    },
    setLeftAlign(pageWidth: number, leftPos: number, rightPos: number) {
      if (this.left) return
      const left = this.leftSidebar
      this.leftAlign = pageWidth - left - rightPos > leftPos - left
    },
    click() {
      if (this.useHover) return
      this.openDropdown()
    },
    closeDropdown() {
      if (!this.isActive) return
      this.isActive = false
      this.$emit('closed')
    },
    closeEvent() {
      if (this.useHover) return
      this.closeDropdown()
    },
    clickawayEvent(event: Event | undefined = undefined) {
      if (!this.clickaway) return
      if (this.clickawayChecker && event?.target) {
        if (this.clickawayChecker(event.target)) return
      }
      this.closeEvent()
    },
    hoverEnter() {
      if (!this.useHover) return
      this.openDropdown()
    },
    hoverExit() {
      if (!this.useHover) return
      this.closeDropdown()
    },
  },
})
