import {
  ApiCacheTTL,
  ApiClient,
  ApiPaginationParams,
  ApiPaginationResult,
} from '~/apiclient/apiclient'
import { capitalize } from '~/assets/ts/utils/strings'
import { UserCommentApi } from '~/apiclient/apicomments'
import {
  AudioCodecType,
  CaptionLanguages,
  LanguageCode3,
  MediaType,
  VideoCodecType,
} from '~/models/media'
import { SeriesApi } from '~/apiclient/apiseries'
import { SpeakerApi } from '~/apiclient/apispeakers'
import { BroadcasterApi } from '~/apiclient/apibroadcasters'
import { HashtagApi } from '~/apiclient/apihashtags'
import { UserFeedSources } from '~/apiclient/apiusers'
import { DateString } from '~/assets/ts/types/date-string'
import { SaIconsType } from '~/assets/ts/types/icons'

export enum SermonSocialSharingPlatform {
  Google = 'google',
  Twitter = 'twitter',
  Facebook = 'facebook',
}

export interface SermonSocialSharingPlatformInfo {
  title: string
  icon: SaIconsType
  textColorClasses: string
}

export function GetSermonSocialSharingPlatformInfo(
  platform: SermonSocialSharingPlatform
): SermonSocialSharingPlatformInfo {
  if (platform === SermonSocialSharingPlatform.Google) {
    return {
      title: 'YouTube',
      icon: 'youtube-brands',
      textColorClasses: 'text-brand-youtube',
    }
  }
  if (platform === SermonSocialSharingPlatform.Facebook) {
    return {
      title: 'Facebook',
      icon: 'facebook-brands',
      textColorClasses: 'text-brand-facebook',
    }
  }
  return {
    title: 'X (Twitter)',
    icon: 'x-twitter-brands',
    textColorClasses: 'text-brand-x-twitter dark:text-white',
  }
}

export enum SermonSocialSharingPrivacy {
  Private = 'private',
  Unlisted = 'unlisted',
  Public = 'public',
}

export interface SermonSocialSharingClip {
  start: number
  end: number
}

export interface SermonSocialSharing {
  platform: SermonSocialSharingPlatform
  message: string
  /** YouTube Only */
  privacy?: SermonSocialSharingPrivacy
  /** YouTube Only */
  title?: string
  useVideoClip?: boolean
}

export function SermonSocialSharingOrDefault(
  platform: SermonSocialSharingPlatform,
  platforms: SermonSocialSharing[],
  message = ''
): SermonSocialSharing {
  const existing = platforms.find((p) => p.platform === platform)
  const google = platform === SermonSocialSharingPlatform.Google
  return {
    platform,
    message: existing?.message || message,
    title: google ? existing?.title || '' : undefined,
    privacy: google
      ? existing?.privacy || SermonSocialSharingPrivacy.Public
      : undefined,
    useVideoClip: !google ? (existing?.useVideoClip ?? true) : undefined,
  }
}

export interface SermonSocialSharingBase {
  twitter: boolean
  facebook: boolean
  google: boolean
}

export interface SermonSocialSharingSettings extends SermonSocialSharingBase {
  platforms: SermonSocialSharing[]
  videoClip?: SermonSocialSharingClip
  publish?: boolean
}

export interface MediaApi {
  type: 'media'
  mediaType: MediaType
  live: boolean
  streamURL: string | null
  eventStreamURL: string | null
  downloadURL: string | null
  /** @deprecated not shown in the API - instead we create it in the Media class */
  rawURL: string | null
  thumbnailImageURL: string | null
  bitrate: number | null
  fileSizeBytes: number | null
  adaptiveBitrate: boolean
  duration: number | null
  audioCodec: AudioCodecType | null
  videoCodec: VideoCodecType | null
  language: LanguageCode3
  mediaFilename: string
  autoGenerated?: boolean
}

export interface MediaSetApi {
  type: 'media_set'
  audio: MediaApi[]
  video: MediaApi[]
  text: MediaApi[]
  caption: MediaApi[]
}

export enum SermonSortOptions {
  NewestPublished = 'newest-published',
  Newest = 'newest',
  Oldest = 'oldest',
  PickDate = 'pickdate',
  Updated = 'updated',
  Title = 'title',
  Speaker = 'speaker',
  LastPlayed = 'lastplayed',
  Language = 'language',
  Event = 'event',
  Downloads = 'downloads',
  Series = 'series',
  Random = 'random',
  Added = 'added',
  CommentCount = 'commentcount',
  // Used for search contexts to allow default of sort by score
  Relevance = '',
  Bible = 'bible',
}

export type SermonApiEventType =
  | 'Audio Book'
  | 'Bible Study'
  | 'Camp Meeting'
  | 'Chapel Service'
  | 'Children'
  | 'Classic Audio'
  | 'Conference'
  | 'Current Events'
  | 'Debate'
  | 'Devotional'
  | 'Funeral Service'
  | 'Midweek Service'
  | 'Podcast'
  | 'Prayer Meeting'
  | 'Question &amp; Answer'
  | 'Radio Broadcast'
  | 'Sermon Clip'
  | 'Special Meeting'
  | 'Sunday - AM'
  | 'Sunday - PM'
  | 'Sunday Afternoon'
  | 'Sunday School'
  | 'Sunday Service'
  | 'Teaching'
  | 'Testimony'
  | 'TV Broadcast'
  | 'Video DVD'
  | 'Wedding'
  | 'Youth'
  | string

export interface SermonRequestOptions extends ApiPaginationParams {
  book?: string
  chapter?: string
  verse?: string
  broadcasterID?: string
  collectionID?: number
  denomination?: string
  eventType?: SermonApiEventType
  featured?: boolean
  followedOnly?: boolean
  hashtag?: string
  highlighted?: boolean
  includeWaveform?: boolean
  languageCode?: string
  lite?: boolean
  cache?: boolean
  publishedSinceTimestamp?: number
  preachedAfterTimestamp?: number
  preachedBeforeTimestamp?: number
  sermonsBeforeSermon?: string
  sermonsAfterSermon?: string
  requireVideo?: boolean
  requireAudio?: boolean
  requirePDF?: boolean
  requireTranscript?: boolean
  series?: string
  searchKeyword?: string
  sortBy?: SermonSortOptions
  /** @deprecated Use speakerID instead */
  speakerName?: string
  speakerID?: number
  sermonIDs?: string[]
  staffPick?: boolean
  /** @deprecated
   * This is only used by the Django embed urls via the fetchSermon middleware.
   * Please use the requireAudio/Video bools instead.
   * */
  type?: string
  year?: number
  month?: number
  day?: number
  audioMinDurationSeconds?: number
  audioMaxDurationSeconds?: number

  /** Broadcaster Key Only */
  includeDrafts?: boolean
  /** Broadcaster Key Only */
  includeScheduled?: boolean
  /** Broadcaster Key Only */
  includePublished?: boolean
}

export const DefaultSermonPageSize = 25
export const DefaultSermonVideoPageSize = 24

export interface SermonApi {
  sermonID: string
  bibleText: string
  broadcaster: BroadcasterApi
  commentCount: number
  captionLanguages: CaptionLanguages
  displayTitle: string
  /** Audio only play count */
  downloadCount: number
  eventType: SermonApiEventType
  displayEventType: string
  favorite: boolean
  featureDate: string
  fullTitle: string
  keywords: string
  hasAudio: boolean
  hasVideo: boolean
  hasPDF: boolean
  hashtags: HashtagApi[]
  languageCode: string
  lite: boolean
  media: MediaSetApi
  moreInfoText: string
  preachDate: DateString
  publishDate: DateString | null
  publishTimestamp: number | null
  series: SeriesApi | null
  speaker: SpeakerApi
  staffPick: boolean
  subtitle: string
  videoThumbnail: string
  videoThumbnailResizable: string
  waveformPeaksUrl: string
  videoDownloadCount: number
  audioDurationSeconds: number
  videoDurationSeconds: number
  feedItemSource?: UserFeedSources[]
  lastAudioAccessTimestamp?: number
  socialSharingSettings?: SermonSocialSharingSettings
  socialSharing?: SermonSocialSharingBase
}

export interface FeaturedSermonCampaignID {
  campaignID: string
}

export async function getFilteredSermonList(
  this: ApiClient,
  options: Partial<SermonRequestOptions>
): Promise<ApiPaginationResult<SermonApi>> {
  const results = [] as SermonApi[]
  let totalCount = 0
  let next
  let nodeType
  let nodeDisplayName

  // Initialize queryParams with selected options properties
  const queryParams: Record<string, any> = {
    ...options,
    // https://gitlab.sermonaudio.com/sa/project-management/main/-/wikis/APIs-for-SermonAudio-2.0#lite-model-objects
    cache: options.cache ?? true,
    liteBroadcaster: true,
    sermonIDs: options.sermonIDs ? options.sermonIDs.join(',') : undefined,
  }

  // 10/30/24 Disabling requireAudio as a default because it is messing with our media type filters
  // and we decided to include sermons without audio by default
  // queryParams.requireAudio = options.requireAudio ?? true
  if (options.type) queryParams[`require${capitalize(options.type)}`] = true

  if (options.highlighted) {
    const { data } = await this.$axios.get(
      `node/broadcasters/${options.broadcasterID}/highlighted_sermons`,
      { params: queryParams }
    )
    data.audioSermon.id = data.audioSermon.sermonID
    results.push(data.audioSermon)
  } else {
    const { data } = await this.$axios.get(`node/sermons`, {
      params: { ...queryParams },
    })
    data.results.forEach((sermon: SermonApi) => {
      results.push(sermon)
    })
    totalCount = data.totalCount
    next = data.next
    nodeType = data.nodeType
    nodeDisplayName = data.nodeDisplayName
  }

  return {
    results,
    totalCount,
    next,
    nodeType,
    nodeDisplayName,
  }
}

export interface WaveformApi {
  isDefault: boolean
  waveform: number[]
}

export async function getWaveform(
  this: ApiClient,
  sermonID: string,
  cache = true
): Promise<WaveformApi> {
  const { data } = await this.$axios.get<WaveformApi>(
    `node/sermons/${sermonID}/waveform`,
    {
      params: {
        cacheTTL: cache ? undefined : 0,
      },
    }
  )
  return data
}

export interface GetSermonOptions {
  lite?: boolean
  /** https://git.sermonaudio.com/project-management/main/-/wikis/APIs-for-SermonAudio-2.0#lite-model-objects */
  liteBroadcaster?: boolean
  allowUnpublished?: boolean
  cacheTTL?: ApiCacheTTL
}

export async function getSermon(
  this: ApiClient,
  sermonID: string,
  params: GetSermonOptions = {}
): Promise<SermonApi> {
  if (params.lite === undefined) params.lite = true
  if (params.liteBroadcaster === undefined) params.liteBroadcaster = true
  const { data } = await this.$axios.get(`node/sermons/${sermonID}`, {
    params,
  })
  return data
}

export async function getSermonComments(
  this: ApiClient,
  sermonID: string,
  page = 1,
  pageSize = 10
): Promise<ApiPaginationResult<UserCommentApi>> {
  const { data } = await this.$axios.get(`node/sermons/${sermonID}/comments`, {
    params: {
      page,
      pageSize,
    },
  })
  return data
}

export async function getFeaturedSermonCampaignID(
  this: ApiClient
): Promise<string> {
  const { data } = await this.$axios.get(
    'node/ads/manage/featuredsermons/current'
  )
  return data.campaignID
}

export async function downloadSermonTranscript(
  this: ApiClient,
  sermonID: string,
  lang3Code: string
) {
  await this.downloadFromApi(
    `/node/sermons/${sermonID}/${lang3Code}/transcript`,
    `${sermonID}-${lang3Code}-transcript.txt`
  )
}
